<template>
    <ValidationObserver ref="observer">
        <b-form>
            <EKDialog
                :title="'إضافة حساب'"
                ref="accountDialog"
                @ok="onSubmit"
                @search="search"
                :placeholder="'ابحث عن حساب محددة'"
                :btnText="'إضافة حساب'"
                @open="Reset_Account_Dto"
                @close="reset"
            >
                <template #body>
                    <EKInputText
                        :rules="[
                            {
                                type: 'required',
                                message: 'الاسم والكنية مطلوبين'
                            }
                        ]"
                        label="الاسم"
                        v-model="accountDto.fullName"
                        placeholder="ادخل الاسم والكنية"
                        name="fullName"
                    />

                    <EKInputText
                        :rules="[
                            { type: 'required', message: 'اسم المستخدم مطلوب' },
                            { type: 'username', message: 'اسم المستدخدم يطول (7 - 29) ويقبل المحارف a-z, A-Z, 0-9, _' }
                        ]"
                        label="اسم المستخدم"
                        v-model="accountDto.username"
                        placeholder="ادخل اسم الستخدم"
                        name="userName"
                    />

                    <EKInputText
                        :rules="[
                            {
                                type: 'required',
                                message: 'كلمة السر مطلوبة'
                            }
                        ]"
                        label="كلمة السر"
                        v-model="accountDto.password"
                        placeholder="ادخل كلمة السر"
                        name="password"
                    />
                    <EKInputSelect
                        label="المنصب"
                        placeholder="المنصب"
                        :rules="[{ type: 'required', message: 'الحقل إجباري' }]"
                        :options="rolesList"
                        name="select"
                        v-model="accountDto.userType"
                    />
                </template>
            </EKDialog>
        </b-form>
    </ValidationObserver>
</template>
<script>
import EKDialog from "@Ekcore/components/EK-dialog";
import { mapState, mapMutations, mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
export default {
    components: {
        EKDialog,
        ValidationObserver,
        EKInputText,
        EKInputSelect
    },
    computed: {
        ...mapState({
            accountDto: state => state.accounts.accountDto,
            rolesList: state => state.accounts.rolesList
        })
    },
    methods: {
        ...mapActions(["addAccount", "deleteAccount"]),
        ...mapMutations(["Reset_Account_Dto"]),
        onSubmit() {
            this.$refs.observer.validate().then(success => {
                if (success) {
                    this.addAccount({
                        fullName: this.accountDto.fullName,
                        username: this.accountDto.username,
                        password: this.accountDto.password,
                        userType: this.accountDto.userType
                    });
                    this.$refs.accountDialog.close();
                    this.$refs.observer.reset();
                }
            });
        },
        open() {
            this.$refs.accountDialog.open();
        },
        reset() {
            this.$refs.observer.reset();
            this.Reset_Account_Dto();
        },
        search(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["username", "Mobile", "address", "dateCreated"],
                query
            });
        }
    }
};
</script>
